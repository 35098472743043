import React from 'react';

export const SuccessIcon = () => (
    <svg viewBox="0 0 16 16" className="success icon">
        <title>Success</title>
        <path d="M5.8052456,11.1375548 L6.51235238,11.8446616 L12.1692066,6.18780732 L10.7549931,4.77359375 L6.51235238,9.01623444 L5.09813882,7.60202088 L3.68392526,9.01623444 L5.8052456,11.1375548 Z M8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 Z M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z"></path>
    </svg>
);

export const FailureIcon = () => (
    <svg viewBox="0 0 16 16" className="error icon">
        <title>Error</title>
        <path d="M9.44974747,8.03553391 L11.5710678,5.91421356 L10.1568542,4.5 L8.03553391,6.62132034 L5.91421356,4.5 L4.5,5.91421356 L6.62132034,8.03553391 L4.5,10.1568542 L5.91421356,11.5710678 L8.03553391,9.44974747 L10.1568542,11.5710678 L11.5710678,10.1568542 L9.44974747,8.03553391 Z M8,16 C12.418278,16 16,12.418278 16,8 C16,3.581722 12.418278,0 8,0 C3.581722,0 0,3.581722 0,8 C0,12.418278 3.581722,16 8,16 Z M8,14 C11.3137085,14 14,11.3137085 14,8 C14,4.6862915 11.3137085,2 8,2 C4.6862915,2 2,4.6862915 2,8 C2,11.3137085 4.6862915,14 8,14 Z"></path>
    </svg>
);
